<template>
  <div>
    <h4>Races</h4>
    
    <data-table
      :data-source="races"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-race"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout de race"
      id="add-race"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingRace"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="add-espece">Code</label>
          <select
            id="add-espece"
            name="add-espece"
            class="form-control"
            v-model="espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
          >
            <option value="" />
            <option
              v-for="(e, i) in especes"
              :value="e.uid"
              :key="i"
            >
              {{ e.libelle }} ( {{ e.code }})
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de la race"
      id="update-race"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingRace"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="edit-espece">Code</label>
          <select
            id="edit-espece"
            name="edit-espece"
            class="form-control"
            v-model="espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
          >
            <option value="" />
            <option
              v-for="(e, i) in especes"
              :value="e.uid"
              :key="i"
            >
              {{ e.libelle }} ( {{ e.code }})
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {ADD_RACE, EDIT_RACE } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
const Espece = ()=> import('../../../components/admin/identification/especeInline.vue')
const Actions = () => import('../../../components/admin/identification/raceAction.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            code: null,
            espece: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedRace: {
            handler(){
                if(this.selectedRace !== null) {
                    this.libelle = this.selectedRace.libelle
                    this.code = this.selectedRace.code
                    this.espece = this.selectedRace.espece
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedRace: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedRace(null)
            this.espece = null
            this.libelle = null
            this.code = null
        },
        addingRace(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                espece: this.espece
            }
            this.$apollo.mutate({
                mutation: ADD_RACE,
                variables: {
                    "race": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Race add successfully on uid ${d.addRace}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingRace(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                espece: this.espece
            }
            this.$apollo.mutate({
                mutation: EDIT_RACE,
                variables: {
                    "race": {
                        ...data
                    }, 
                    "uid": this.selectedRace.uid
                },
                update: () => {
                    console.log(`Race  ${this.selectedRace.libelle} edit successfully on uid ${this.selectedRace.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            races: 'identification/races',
            especes: 'identification/especes',
            selectedRace: 'selectedObject'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true},
                {label: 'Espece', name: 'espece', type: COMPONENT_TYPE, component: Espece},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style>

</style>